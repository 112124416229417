import * as jdenticon from 'jdenticon';

/**
 * Configures jdenticon with custom settings.
 */
jdenticon.configure({
  backColor: '#ffffff', // Transparent background
});

/**
 * Generates an identicon SVG from the given text.
 * @param {string} text - The text to generate the identicon from.
 * @param {number} size - The size of the identicon.
 * @returns {string} - The SVG string of the generated identicon.
 */
export const generateIdenticon = (text: string, size: number = 100): string => {
  // Step 1: Generate the identicon SVG
  const identiconSvg = jdenticon.toSvg(text || 'default', size);

  // Step 2: Convert SVG to base64
  const base64Svg = btoa(identiconSvg);

  // Step 3: Return base64 data URL
  return `data:image/svg+xml;base64,${base64Svg}`;
};
