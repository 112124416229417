import { initReactQueryAuth } from 'react-query-auth';
import { Spinner } from '@primer/react';
import {
  loginWithEmailAndPassword,
  getUser,
  registerWithEmailAndPassword,
  UserResponse,
  LoginCredentialsDTO,
  RegisterCredentialsDTO,
  AuthUser,
} from '@/features/auth';
import storage from '@/utils/storage';
import { axios } from './axios';

async function handleUserResponse(data: any) {
  const { access_token, ...user } = data;
  storage.setToken(access_token);
  if (access_token && storage.getTeam() === null) {
    const team = await axios.get(`/v1/team/teams?access_token=${access_token}`);
    if (team?.data[0]?._id) {
      storage.setTeam(team?.data[0]);
    }
  }
  return user;
}

async function loadUser(): Promise<AuthUser | null> {
  const token = storage.getToken();
  if (token) {
    // Simulate fetching user data using the stored token
    // Replace this with actual logic if you need to validate the token or fetch user details from the server
    return {
      status: 'authenticated',
      avatar_url: '',
      name: '',
      access_token: token,
      username: '', // Add the missing 'username' property
      email: '', // Add the missing 'email' property
      // Add any additional properties required by the AuthUser type
    };
  }
  return null;
}

async function loginFn(data: LoginCredentialsDTO) {
  const response = await loginWithEmailAndPassword(data);
  const user = await handleUserResponse(response);
  return user;
}

async function registerFn(data: RegisterCredentialsDTO) {
  const response = await registerWithEmailAndPassword(data);
  const user = await handleUserResponse(response);
  return user;
}

async function logoutFn() {
  storage.clearToken();
  storage.clearTeam();
  window.location.assign(window.location.origin as unknown as string);
}

const authConfig = {
  loadUser,
  loginFn: handleUserResponse,
  registerFn: handleUserResponse,
  logoutFn,
  LoaderComponent() {
    return (
      <div className="w-screen h-screen flex justify-center items-center">
        <Spinner size="large" />
      </div>
    );
  },
};

export const { AuthProvider, useAuth } = initReactQueryAuth<
  AuthUser | null,
  unknown,
  LoginCredentialsDTO,
  RegisterCredentialsDTO
>(authConfig);
