import { useState, useEffect, useRef } from 'react';
import { API_URL } from '@/config';
import storage from '@/utils/storage';

interface UseSSEOptions extends EventSourceInit {
  onMessage?: (event: MessageEvent) => void;
  onError?: (event: Event) => void;
}

interface SSEState<T> {
  data: T | null;
  error: string | null;
  isLoading: boolean;
}

export const useSSE = <T>(
  endpoint: string,
  options: UseSSEOptions = {},
  enabled: boolean = true,
  shouldClose: boolean = false, // New prop to control closing
  isPublicStream: boolean = false,
  responseType: string = 'json',
  apiVersion?: string
): SSEState<T> => {
  const [data, setData] = useState<T | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const eventSourceRef = useRef<EventSource | null>(null);
  const access_token = storage.getToken();
  const apiUrl = API_URL + (apiVersion ? `/${apiVersion}` : "/v1");
  const teamId = storage.getTeam()?._id;

  const url = isPublicStream
    ? `${apiUrl}${endpoint}`
    : `${apiUrl}${endpoint}?access_token=${access_token}&x_team=${teamId}`;

  useEffect(() => {
    if (!enabled || !endpoint) return;

    if (!isPublicStream && (!access_token || !teamId)) {
      if (!access_token) console.error('[SSE Connection] No access token found');
      if (!teamId) console.error('[SSE Connection] No team ID found');
      return;
    }

    // Close any previous EventSource connection
    if (eventSourceRef.current) {
      eventSourceRef.current.close();
    }

    const eventSource = new EventSource(url, options);
    eventSourceRef.current = eventSource;

    eventSource.onmessage = (event: MessageEvent) => {
      if (options.onMessage) {
        options.onMessage(event);
      } else {
        try {
          const parsedData = responseType === 'json' ? JSON.parse(event.data) : event.data;
          setData(parsedData);
          setIsLoading(false);
        } catch (e) {
          handleError('Failed to parse event data');
        }
      }
    };

    eventSource.onerror = (event: Event) => {
      if (options.onError) {
        options.onError(event);
      } else {
        handleError('An error occurred with the SSE connection');
      }
      eventSource.close(); // Close the connection on error
    };

    // return () => {
    //   if (eventSource) {
    //     eventSource.close();
    //   }
    // };
  }, [endpoint]);

  useEffect(() => {
    if (shouldClose && eventSourceRef.current) {
      eventSourceRef.current.close();
      // console.log('Closing EventSource connection');
    }
  }, [shouldClose]);

  const handleError = (message: string) => {
    setError(message);
    setIsLoading(false);
  };

  return { data, error, isLoading };
};
