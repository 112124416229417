import { nanoid } from 'nanoid';
import create from 'zustand';

type TeamCreditsStore = {
  credits: any;
  setCredits: (credits: any) => void;
};

export const useTeamCreditsStore = create<TeamCreditsStore>((set) => ({
  credits: 0,
  setCredits: (credits) => {
    set((state) => ({
      credits,
    }));
  },
}));
