import { useQuery } from 'react-query';
import { axios } from '@/lib/axios';
import { useNotificationStore } from '@/stores/notifications';
import storage from '@/utils/storage';

export const getUserDetails = async (): Promise<any> => {
  const access_token = storage.getToken();

  if (!access_token) {
    return;
  }
  try {
    const response = await axios.get('https://api.github.com/user');
    return response;
  } catch (error) {
    // console.error('Error fetching user details:', error);
    // throw error;
  }
};

export const useGetUserDetails = (config = {}) => {
  const { addNotification } = useNotificationStore();

  return useQuery<any, Error>({
    queryKey: 'userDetails',
    queryFn: () => getUserDetails(),
    onError: (error) => {
      addNotification({
        type: 'error',
        title: 'Failed to Fetch User Details',
        message: error.message,
      });
    },
    onSuccess: (data) => {},
    ...config,
  });
};
