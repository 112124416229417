import { useQuery } from 'react-query';
import { axios } from '@/lib/axios';
import { useNotificationStore } from '@/stores/notifications';
import { API_URL } from '@/config';
import storage from '@/utils/storage';

export const getFlowscaleUser = async (): Promise<any> => {
  const access_token = storage.getToken();

  if (!access_token) {
    return;
  }
  try {
    const response = await axios.get(`${API_URL}/v1/user`, {
      headers: {
        'access-token': access_token,
      },
    });
    return response.data;
  } catch (error) {
    // console.error('Error getFlowscaleUser:', error);
    // throw error;
  }
};

export const useGetFlowscaleUser = (config = {}) => {
  const { addNotification } = useNotificationStore();

  return useQuery<any, Error>({
    queryKey: ['flowscaleUser'],
    // refetchInterval: 10000,
    queryFn: () => getFlowscaleUser(),
    onError: (error) => {},
    onSuccess: (data) => {
      // Handle success here if needed
    },
    ...config,
  });
};
