import React, { ReactNode } from 'react';
import LogoWithFullName from '@/assets/logo-with-fullname.svg';
import { Box } from '@primer/react';
import { useNavigate } from 'react-router-dom';
import { UserNavigation } from './UserNavigation';
import { useGetUserDetails } from '@/features/auth';
import { Head } from '../Head';
import { useAuth } from '@/lib/auth';
import { TeamsDropdown } from '@/features/teams';

interface PricingLayoutProps {
  children: ReactNode;
}

export const PricingLayout: React.FC<PricingLayoutProps> = ({ children }) => {
  const { data: userDetails } = useGetUserDetails();

  const auth = useAuth();
  const navigate = useNavigate();

  return (
    <Box className="flex flex-col h-screen overflow-hidden bg-[#16191D]">
      <Head title="Pricing" />
      <div className="relative justify-between items-center px-[120px] flex-shrink-0 flex h-16 bg-[#16191D] border-b border-[#343B45]">
        <div className="flex items-center gap-2">
          <img
            src={LogoWithFullName}
            onClick={() => (auth?.user ? navigate('/app/repositories') : null)}
            className="h-6 cursor-pointer"
            alt="logo"
          />
        </div>
        <div className="ml-auto mr-6">{auth?.user ? <TeamsDropdown /> : null}</div>
        <UserNavigation userDetails={userDetails} />
      </div>
      <main className="flex-1 relative overflow-y-auto scrollbar-hide px-[120px] py-5 focus:outline-none">
        {children}
      </main>
    </Box>
  );
};
