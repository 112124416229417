import React, { useEffect } from 'react';
import { ActionMenu, Avatar, Box, Label } from '@primer/react';
import { DotFillIcon } from '@primer/octicons-react';
import { Buildings, Check, CurrencyDollarSimple, Gear, Spinner } from 'phosphor-react';
import { useGetTeams } from '../api/getTeams';
import storage from '@/utils/storage';
import { generateIdenticon } from '@/utils/randomLogos';
import { useNavigate } from 'react-router-dom';
import { useGetTeam } from '../api/getTeam';

type TeamsDropdownProps = {};

export const TeamsDropdown: React.FC<TeamsDropdownProps> = () => {
  const { data: teams, isLoading } = useGetTeams();
  const { data: selectedTeam, isLoading: isSelectedTeamLoading } = useGetTeam();
  const { setTeam } = storage;
  const navigate = useNavigate();

  useEffect(() => {
    const currentPath = window.location.pathname;
    if (currentPath !== '/pricing') {
      if (selectedTeam?.data?.plan === 'free') {
        navigate('/pricing');
      }
    }
  }, [selectedTeam]);

  return (
    <ActionMenu>
      <ActionMenu.Button
        disabled={false}
        alignContent="center"
        sx={{
          border: `1px solid #464b51`,
          borderRadius: '6px',
          fontSize: '12px',
          backgroundColor:
            'var(--bgColor-neutral-muted, var(--color-neutral-subtle, rgba(99,110,123,0.1)))',
        }}
      >
        {isLoading || isSelectedTeamLoading ? (
          <Spinner size={18} className="animate-spin" />
        ) : (
          <Box className="flex items-center gap-1">
            <span className="flex items-center gap-1">
              <Avatar
                className="mr-2"
                src={
                  selectedTeam?.data?.logo
                    ? selectedTeam?.data?.logo
                    : generateIdenticon(selectedTeam?.data?.name)
                }
              />
              <span className="text-sm">{selectedTeam?.data?.name}</span>
              <Label
                className="ml-2"
                variant={
                  selectedTeam?.data?.plan && selectedTeam?.data?.plan !== 'free'
                    ? 'success'
                    : 'primary'
                }
              >
                {selectedTeam?.data?.plan?.toUpperCase() || 'FREE'}
              </Label>
            </span>
          </Box>
        )}
      </ActionMenu.Button>
      <ActionMenu.Overlay
        width="small"
        height="fit-content"
        align="end"
        sx={{
          borderRadius: '0.25rem',
        }}
      >
        <Box className="flex flex-col">
          <span className="text-sm border-b border-[#636C76] px-5 py-2">Switch Organization:</span>
          <Box className="flex flex-col">
            {teams?.data?.map((team: any, index: any) => (
              <Box
                block
                onClick={() => {
                  if (selectedTeam?.data?._id !== team?._id) {
                    setTeam(team);
                    if (team?.plan === 'free') {
                      navigate('/pricing');
                    } else {
                      window.location.href = '/app/repositories';
                    }
                  }
                }}
                key={index}
                disabled={selectedTeam?.data?._id === team?._id}
                className={`flex items-center gap-2 hover:bg-[#3f4750] cursor-pointer px-4 py-3 ${
                  selectedTeam?.data?._id === team?._id ? 'bg-[#3f4750] cursor-not-allowed' : ''
                }`}
              >
                <Avatar
                  className="mr-2"
                  src={team?.logo ? team?.logo : generateIdenticon(team?.name)}
                />
                <span className="text-sm">{team?.name}</span>
                <Label
                  className="ml-2"
                  variant={team?.plan && team?.plan !== 'free' ? 'success' : 'primary'}
                >
                  {team?.plan?.toUpperCase() || 'FREE'}
                </Label>
                {selectedTeam?.data?._id === team?._id ? (
                  <Check className="ml-auto" size={18} />
                ) : (
                  <></>
                )}
              </Box>
            ))}
          </Box>
          {selectedTeam?.data?.plan && selectedTeam?.data?.plan !== 'free' ? (
            <span
              onClick={() => {
                navigate('/app/repositories?settings');
              }}
              className="text-sm border-t border-[#636C76] px-5 py-3 cursor-pointer hover:bg-[#3f4750]"
            >
              <span className="flex items-center gap-2">
                <Gear size={16} />
                <span>Organization Settings</span>
              </span>
            </span>
          ) : (
            <></>
          )}
        </Box>
      </ActionMenu.Overlay>
    </ActionMenu>
  );
};