import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import logo from '@/assets/logo-with-fullname.svg';
import { Head } from '@/components/Head';
import storage from '@/utils/storage';
import { Spinner } from '@primer/react';
import { useAuth } from '@/lib/auth';
import { useGetUserStatus } from '@/features/auth';
import { useGetUserDetails } from '@/features/auth/api/getUserDetails';
import { useGetTeam } from '@/features/teams/api/getTeam';

export const Landing = () => {
  const navigate = useNavigate();
  const { user } = useAuth();
  const [isLoggingIn, setIsLoggingIn] = useState(false);

  const { data: userDetails } = useGetUserDetails();
  const {
    data: userStatus,
    isLoading: statusLoading,
    error: statusError,
  } = useGetUserStatus(user?.username ? user?.username : userDetails?.login || '', {
    enabled: !!user,
  });

  const token = storage.getToken();
  const team = storage.getTeam();
  // const { data: team, isLoading: isTeamLoading } = useGetTeam();

  // Handle redirect when userStatus is loaded
  useEffect(() => {
    if (userStatus && userStatus?.data && token && team?.status && team?.plan) {
      console.log('[DEBUG] STATUS:', userStatus?.data?.status, team);
      if (
        userStatus?.data?.status === 'active' &&
        team?.status === 'active' &&
        team?.plan !== 'free'
      ) {
        navigate('/app/repositories');
      } else {
        navigate('/pricing');
      }
    }
  }, [userStatus, team, navigate]);

  const handleStart = () => {
    if (token) {
      navigate('/app/repositories');
    } else {
      navigate('/auth/login');
    }
  };

  return (
    <>
      <Head description="Welcome to bulletproof react" />
      <div className="bg-[#16191D] h-[100vh] flex items-center">
        <div className="max-w-7xl flex bg-[#121417] rounded-3xl flex-col gap-4 mx-auto text-center py-12 px-4 sm:px-6 lg:py-16 lg:px-16">
          <img src={logo} alt="react" />
          <p>Deploy your ComfyUI Workflows</p>
          <div className="mt-8 flex justify-center">
            <div className="flex rounded-3xl  bg-gradient-to-r from-[#1659da] to-[#16CFDA] p-0.5">
              <button
                onClick={handleStart}
                className="flex gap-3 items-center hover:bg-transparent bg-[#121417] text-white justify-center text-sm px-14 py-3 rounded-3xl"
              >
                {isLoggingIn ? (
                  <div className="flex gap-3 items-center">
                    <Spinner size="small" />
                    Logging in...
                  </div>
                ) : (
                  <h3>Get started</h3>
                )}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
