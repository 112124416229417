import { Suspense } from 'react';
import { Outlet } from 'react-router-dom';

import { Spinner } from '@primer/react';
import { MainLayout } from '@/components/Layout';
import { lazyImport } from '@/utils/lazyImport';
import { NotFoundPage } from '@/features/misc';

const { Repositories } = lazyImport(() => import('@/features/repositories'), 'Repositories');
const { Profile } = lazyImport(() => import('@/features/users'), 'Profile');

const App = () => {
  return (
    <MainLayout>
      <Suspense
        fallback={
          <div className="h-full w-full flex items-center justify-center">
            <Spinner size="large" />
          </div>
        }
      >
        <Outlet />
      </Suspense>
    </MainLayout>
  );
};

const RepositoryPage = () => {
  return (
    <Repositories>
      <Suspense
        fallback={
          <div className="h-full w-full flex items-center justify-center">
            <Spinner size="large" />
          </div>
        }
      >
        <Outlet />
      </Suspense>
    </Repositories>
  );
};

const ProfilePage = () => {
  return (
    <Profile>
      <Suspense
        fallback={
          <div className="h-full w-full flex items-center justify-center">
            <Spinner size="large" />
          </div>
        }
      >
        <Outlet />
      </Suspense>
    </Profile>
  );
};

export const protectedRoutes = [
  {
    path: '/app',
    element: <App />,
    children: [
      { path: 'repositories', element: <RepositoryPage /> },
      { path: 'profile', element: <ProfilePage /> },
      { path: '*', element: <NotFoundPage /> },
    ],
  },
];
