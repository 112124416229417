import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Colors,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import { FC } from 'react';

// Registering chart components with ChartJS
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Colors
);

// Type definitions for props
interface LineChartProps {
  className?: string;
  title?: string;
  message: {
    labels: string[];
    datasets: {
      label: string;
      data: number[];
      backgroundColor?: string;
      borderColor?: string;
    }[];
  };
}

export const LineChart: FC<LineChartProps> = ({ className, message,title }) => {
  const data = {
    labels: message?.labels,
    datasets: message?.datasets,
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'bottom' as const, // TS strictness
      },
      title: {
        display: true,
        text: title,
      },
      colors: {
        enabled: true,
      },
    },
  };

  return (
    // <div className={`my-2.5 w-full ${className}`}>
    <Line options={options} data={data} />
    // </div>
  );
};
