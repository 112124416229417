import create from 'zustand';

type ErrorState = {
  isOpen: boolean;
  errorMessage: string | null;
  errorTitle?: string;
  setError: (title: string, message: string) => void;
  clearError: () => void;
};

export const useErrorStore = create<ErrorState>((set) => ({
  isOpen: false,
  errorMessage: null,
  errorTitle: undefined,
  setError: (title: string, message: string) =>
    set({ isOpen: true, errorTitle: title, errorMessage: message }),
  clearError: () => set({ isOpen: false, errorMessage: null, errorTitle: undefined }),
}));
