import create from 'zustand';
import { useNavigate } from 'react-router-dom';

interface TimerModalState {
  isOpen: boolean;
  title: string;
  message: string;
  redirectUrl: string;
  duration: number;
  startModal: (title: string, message: string, redirectUrl: string, duration: number) => void;
  closeModal: () => void;
}

export const useTimerModalStore = create<TimerModalState>((set, get) => ({
  isOpen: false,
  title: '',
  message: '',
  redirectUrl: '',
  duration: 0,
  startModal: (title, message, redirectUrl, duration) => {
    set({ isOpen: true, title, message, redirectUrl, duration });
  },
  closeModal: () => set({ isOpen: false, title: '', message: '', redirectUrl: '', duration: 0 }),
}));
