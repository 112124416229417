import { nanoid } from 'nanoid';
import create from 'zustand';

type DeploymentDependenciesStore = {
  repoData: any;
  activeDeploymentId: string | null;
  deploymentData: any;
  isOpen: boolean;
  is_workspace: boolean;
  workspace_id: string | null;
  setRepoData: (repoData: any) => void;
  setDeploymentId: (id: string) => void;
  openModal: (
    deploymentId: string,
    deploymentData: any,
    is_workspace: boolean,
    workspace_id: string | null
  ) => void;
  closeModal: () => void;
  getIsDeploying: (repoId: string) => boolean;
  setIsDeploying: (repoId: string, value: boolean) => void;
  removeIsDeploying: (repoId: string) => void;
  getIsWorkspaceDeploying: (workspaceId: string) => any;
  setIsWorkspaceDeploying: (workspaceId: string, data: any) => void;
  removeIsWorkspaceDeploying: (workspaceId: string) => void;
};

// get the isDeploying status from the local storage
const getIsDeploying = (repoId: string): boolean => {
  const isDeploying = JSON.parse(localStorage.getItem('isDeploying') || '{}');
  return isDeploying[repoId] || false;
};

// set the isDeploying status in the local storage
const setIsDeploying = (repoId: string, value: boolean): void => {
  const isDeploying = JSON.parse(localStorage.getItem('isDeploying') || '{}');
  isDeploying[repoId] = value;
  localStorage.setItem('isDeploying', JSON.stringify(isDeploying));
};

// remove the isDeploying status from the local storage
const removeIsDeploying = (repoId: string): void => {
  const isDeploying = JSON.parse(localStorage.getItem('isDeploying') || '{}');
  delete isDeploying[repoId];
  if (Object.keys(isDeploying).length === 0) {
    localStorage.removeItem('isDeploying');
  } else {
    localStorage.setItem('isDeploying', JSON.stringify(isDeploying));
  }
};

// get the isWorkspaceDeploying status from the local storage
const getIsWorkspaceDeploying = (workspaceId: string): any => {
  const workspaces = JSON.parse(localStorage.getItem('workspaces') || '{}');
  return workspaces[workspaceId] || null;
};

// set the isWorkspaceDeploying status in the local storage
const setIsWorkspaceDeploying = (workspaceId: string, data: any): void => {
  const workspaces = JSON.parse(localStorage.getItem('workspaces') || '{}');
  workspaces[workspaceId] = data;
  localStorage.setItem('workspaces', JSON.stringify(workspaces));
};

// remove the isWorkspaceDeploying status from the local storage
const removeIsWorkspaceDeploying = (workspaceId: string): void => {
  const workspaces = JSON.parse(localStorage.getItem('workspaces') || '{}');
  delete workspaces[workspaceId];
  if (Object.keys(workspaces).length === 0) {
    localStorage.removeItem('workspaces');
  } else {
    localStorage.setItem('workspaces', JSON.stringify(workspaces));
  }
};

export const useDeploymentDependenciesStore = create<DeploymentDependenciesStore>((set) => ({
  repoData: null,
  activeDeploymentId: null,
  deploymentData: {
    custom_nodes: {},
    models: {},
  },
  isOpen: false,
  is_workspace: false,
  workspace_id: null,
  setRepoData: (repoData: any) => {
    set((state) => ({
      ...state,
      repoData,
    }));
  },
  setDeploymentId: (id: string) => {
    set((state) => ({
      ...state,
      activeDeploymentId: id,
    }));
  },
  openModal: (deploymentId, deploymentData, is_workspace, workspace_id) => {
    set((state) => ({
      ...state,
      activeDeploymentId: deploymentId,
      deploymentData,
      isOpen: true,
      is_workspace,
      workspace_id,
    }));
    console.log('Open modal with deploymentId:', deploymentId);
    // Add code to open the modal here
  },
  closeModal: () => {
    set((state) => ({
      ...state,
      activeDeploymentId: null,
      deploymentData: null,
      isOpen: false,
    }));
    // Add code to close the modal here
  },
  getIsDeploying,
  setIsDeploying,
  removeIsDeploying,
  getIsWorkspaceDeploying,
  setIsWorkspaceDeploying,
  removeIsWorkspaceDeploying,
}));
