import React, { useEffect, useState } from 'react';
import { useNavigate, Link, useLocation, useParams } from 'react-router-dom';
import LogoWithFullName from '@/assets/logo-with-fullname.svg';
import {
  CaretDown,
  GithubLogo,
  GitFork,
  Lock,
  RocketLaunch,
  Spinner,
  ArrowLeft,
  Info,
} from 'phosphor-react';
import logo from '@/assets/logo.svg';
import { Avatar, Box, Button, Token, ActionMenu, ActionList } from '@primer/react';
import { TeamsDropdown } from '@/features/teams';
import { DropdownMorphed } from '../Elements/Dropdown';
import { useAppContext } from '@/context/AppContext';
import { PodsStatus, usePodStatus } from '@/features/pods';
import { useGetTeam } from '@/features/teams/api/getTeam';
import { generateIdenticon } from '@/utils/randomLogos';
// import { useSSE } from '@/lib/useSSE';
import { UserNavigation } from './UserNavigation';
import CustomButton from '../Elements/Button/CustomButton';
import { useGetVolumeId } from '@/features/app';

export const TopBar = ({
  userDetails,
  repoDetails,
  userCredits,
}: {
  userDetails: any;
  repoDetails: any;
  userCredits: any;
}) => {
  const [isPrivate, setIsPrivate] = useState(false);
  const [isForked, setIsForked] = useState(false);

  const { projectId } = useParams<{ projectId: any }>();

  const { allPodsSSE } = usePodStatus(true);
  const { data: team, isLoading: isTeamLoading } = useGetTeam();

  const { data: volume }: { data: any } = useGetVolumeId(team?.data?._id);

  const isUserTeamOwner =
    team?.data?.members?.find((member: any) => member?.role === 'owner')?.username ===
    userDetails?.login;

  const {
    isLoggedUserSameAsRepoUser,
    setRepoOwnerUserName,
    repoOwnerUserName,
    setLoggedInUsername,
    setRepoName,
    repo_name,
    loggedInUsername,
    setSearchRepositories,
    searchRepositories,
    setRepoUrl,
    repoUrl,
    setRepoBranchName,
    setIsDeploymentCheckModalOpen,
    activeTabModule,
    setUserVolumeId,
    setIsUserTeamOwner,
    setXTeamId,
    setAllPodsSSEData,
    setTeamApiData,
  } = useAppContext();

  const navigate = useNavigate();
  const location = useLocation();

  const showLogoWithFullName =
    location.pathname.includes('/app/repositories') &&
    !location.pathname.includes('/app/repositories/');
  const hideOnProfilePage = location.pathname.includes('/app/profile');

  useEffect(() => {
    setAllPodsSSEData(allPodsSSE);
  }, [allPodsSSE]);

  useEffect(() => {
    if (projectId && repoDetails) {
      setRepoName(repoDetails?.name);
      setRepoOwnerUserName(repoDetails?.owner?.login);
      setRepoUrl(repoDetails?.html_url);
      setIsPrivate(repoDetails?.private);
      setIsForked(repoDetails?.fork);
      setRepoBranchName(repoDetails?.default_branch);
    }
  }, [repoDetails]);

  useEffect(() => {
    setXTeamId(team?.data?._id);
    setTeamApiData(team);
  }, [team]);

  useEffect(() => {
    setIsUserTeamOwner(isUserTeamOwner);
    setLoggedInUsername(userDetails?.login);
    if (volume) {
      setUserVolumeId(volume?.data[0]?.volume_id);
    }
  }, [userDetails, volume]);

  const menuItems = [
    {
      icon: ArrowLeft,
      name: 'Back to Projects',
      url: '/app/repositories',
      newTab: false,
    },
    {
      icon: Info,
      name: 'Help',
      url: 'https://docs.flowscale.ai',
      newTab: true,
      // trailingLogo: ArrowSquareOut,
    },
  ];

  const handleDeployButtonClick = () => {
    setIsDeploymentCheckModalOpen(true);
  };

  const getDifferingGPUStatus = () => {
    if (!allPodsSSE || allPodsSSE.length === 0) return 'No pods found';
    const differingGPUStatuses = allPodsSSE
      .flatMap((pod: any) =>
        pod.clusters.map((cluster: any) => {
          const gpuStatuses = cluster.containers
            .filter((container: any) => container.gpu_status !== undefined)
            .map((container: any) => container.gpu_status);

          // console.log('[DEBUG] gpuStatuses', gpuStatuses);

          const differingStatus = gpuStatuses.find(
            (status: any, _: any, arr: any) => status !== arr[0]
          );

          return {
            podName: pod.name,
            clusterName: cluster.name || `Cluster ${cluster._id}`,
            gpuStatus: differingStatus || gpuStatuses[0],
          };
        })
      )
      .filter((status: any) => status.gpuStatus !== undefined);
    return differingGPUStatuses;
  };

  const differingGPUStatuses = getDifferingGPUStatus();

  // console.log('[DEBUG] differingGPUStatuses', differingGPUStatuses);

  // const forkRepositoryMutation = useForkRepository({
  //   onSuccess: (data: any) => {
  //     addUserRepository({ repo_id: data?.id, repo_name });
  //     navigate('/app/repositories');
  //   },
  // });

  // const handleForkRepository = async () => {
  //   try {
  //     await forkRepositoryMutation.mutateAsync({
  //       owner: repoOwnerUserName,
  //       repo_name,
  //       default_branch_only: false,
  //     });
  //   } catch (error) {
  //     // console.error('Error forking repository:', error);
  //   }
  // };

  return (
    <div
      className={`${
        showLogoWithFullName ? 'px-[120px]' : 'px-2'
      } relative py-2 flex justify-between w-full border-b border-[#343B45]`}
    >
      <Box className="flex items-center w-full justify-center gap-2">
        {showLogoWithFullName ? (
          <Box className="flex items-center justify-between w-full">
            <img
              src={LogoWithFullName}
              onClick={() => navigate('/app/repositories')}
              className="h-6 cursor-pointer"
              alt="logo"
            />
            <Box className="flex items-center gap-3">
              {/* <FormControl>
              <FormControl.Label visuallyHidden>Default label</FormControl.Label>
              <TextInput
                leadingVisual={MagnifyingGlass}
                placeholder="Search projects"
                sx={{ width: '320px', backgroundColor: '#16191D' }}
                size="medium"
                value={searchRepositories}
                onChange={(e) => {
                  setSearchRepositories(e.target.value);
                  navigate(`/app/repositories?projects`);
                }}
                trailingAction={
                  searchRepositories.length > 0 ? (
                    <TextInput.Action
                      onClick={(e) => {
                        setSearchRepositories('');
                        e.preventDefault();
                      }}
                      icon={XCircle}
                      aria-label="Clear"
                      sx={{
                        color: 'fg.subtle',
                      }}
                    />
                  ) : undefined
                }
              />
            </FormControl> */}
              <TeamsDropdown />
              <DropdownMorphed
                status={differingGPUStatuses?.[0]?.gpuStatus}
                credits={userCredits?.credits?.toFixed(2)}
                creditsLoading={!userCredits}
              >
                <PodsStatus allPodsSSE={allPodsSSE} />
              </DropdownMorphed>

              <UserNavigation
                userDetails={userDetails}
                menuClassName="top-8 left-0 z-[40] bg-[#272b31]"
              />
            </Box>
          </Box>
        ) : (
          <Box className="flex items-center justify-between w-full">
            <ActionMenu>
              <ActionMenu.Button
                size="small"
                trailingAction={CaretDown}
                sx={{ backgroundColor: '#22262C', height: '35px' }}
              >
                <img src={logo} alt="logo" />
              </ActionMenu.Button>

              <ActionMenu.Overlay>
                <ActionList
                  sx={{
                    padding: '12px 8px',
                  }}
                >
                  {menuItems.map((item, index) => (
                    <Box
                      key={index}
                      onClick={() => {
                        if (item.newTab) {
                          window.open(item.url, '_blank');
                        } else {
                          navigate(item.url);
                        }
                      }}
                      className="flex rounded-md justify-between w-full items-center gap-2 py-2 px-3 hover:bg-gray-700 hover:text-white cursor-pointer text-xs"
                    >
                      <Box className="flex items-center gap-2">
                        {item.icon && <item.icon size={18} />}
                        {item.name}
                      </Box>
                      {/* {item.trailingLogo && <item.trailingLogo size={18} />} */}
                    </Box>
                  ))}
                </ActionList>
              </ActionMenu.Overlay>
            </ActionMenu>

            <Box
              className="flex justify-center items-center gap-3 absolute"
              sx={{
                left: '50%',
                transform: 'translateX(-50%)',
              }}
            >
              {isTeamLoading ? (
                <Spinner size={18} className="animate-spin" />
              ) : (
                <ol className="flex items-center">
                  <li>
                    <Link to="/app/repositories">
                      <Box className="flex items-center gap-1">
                        <Avatar
                          className="mr-1 border border-gray-700"
                          size={26}
                          src={
                            team?.data?.logo
                              ? team?.data?.logo
                              : generateIdenticon(team?.data?.name)
                          }
                        />
                        <span className="hover:border-b">{team?.data?.name}</span>
                      </Box>
                    </Link>
                  </li>
                  <li className="mx-2">/</li>
                  <li>
                    <p aria-current="page">{hideOnProfilePage ? 'profile' : repo_name}</p>
                  </li>
                </ol>
              )}
              {isPrivate && (
                <div
                  style={{
                    borderRadius: '24px',
                    padding: '6px 12px',
                    color: '#D7D7DB',
                    backgroundColor: '#343B45',
                    fontSize: '12px',
                  }}
                >
                  <Lock />
                </div>
              )}
              {isForked && (
                <Token
                  leadingVisual={GitFork}
                  sx={{
                    borderRadius: '24px',
                    padding: '12px 14px',
                    color: '#D7D7DB',
                    backgroundColor: '#343B45',
                    fontSize: '12px',
                  }}
                  text={'Forked'}
                />
              )}
              {repoOwnerUserName && (
                <Button
                  leadingVisual={GithubLogo}
                  sx={{
                    border: '1px solid #636C76',
                    borderRadius: '24px',
                    color: '#D7D7DB',
                    fontSize: '12px',
                    backgroundColor: 'transparent',
                  }}
                  onClick={(e) => {
                    e.stopPropagation();
                    window.open(repoUrl, '_blank');
                  }}
                >
                  View on Github
                </Button>
              )}
            </Box>

            {!hideOnProfilePage && (
              <Box className="flex items-center gap-3">
                <DropdownMorphed
                  status={differingGPUStatuses?.[0]?.gpuStatus}
                  credits={userCredits?.credits?.toFixed(2)}
                  creditsLoading={!userCredits}
                >
                  <PodsStatus allPodsSSE={allPodsSSE} />
                </DropdownMorphed>

                {activeTabModule === 'editor' ? (
                  <CustomButton
                    leadingVisual={RocketLaunch}
                    style={{
                      backgroundColor: '#fff',
                      color: '#16191D',
                      padding: '4px 11px',
                      fontSize: '14px',
                    }}
                    hoverStyle={{
                      color: '#C4C4CA',
                      border: '1px solid #636C76',
                      backgroundColor: '#16191D',
                      fontSize: '14px',
                    }}
                    onClick={handleDeployButtonClick}
                  >
                    Deploy
                  </CustomButton>
                ) : null}
              </Box>
            )}
          </Box>
        )}
      </Box>

      {/* {!isLoggedUserSameAsRepoUser && !showLogoWithFullName && !hideOnProfilePage && (
            <Button
              leadingVisual={GitFork}
              sx={{
                border: '1px solid #636C76',
                padding: '8px, 16px, 8px, 16px',
                borderRadius: '24px',
                color: '#D7D7DB',
                fontSize: '14px',
                backgroundColor: 'transparent',
              }}
              onClick={(e) => {
                e.stopPropagation();
                handleForkRepository();
              }}
            >
              Fork this repository
            </Button>
          )} */}
    </div>
  );
};
