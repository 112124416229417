import { useQuery } from 'react-query';
import { axios } from '@/lib/axios';
import { useNotificationStore } from '@/stores/notifications';
import { API_URL } from '@/config';

export const getUserStatus = async (username: string): Promise<any> => {
  if (!username) {
    return;
  }
  try {
    const response = await axios.get(`${API_URL}/v1/user/${username}`);
    return response;
  } catch (error) {
    // console.error('Error getUserStatus:', error);
    // throw error;
  }
};

export const useGetUserStatus = (username: string, config = {}) => {
  const { addNotification } = useNotificationStore();

  return useQuery<any, Error>({
    queryKey: ['userStatus', username],
    refetchInterval: 10000,
    queryFn: () => getUserStatus(username),
    onError: (error) => {},
    onSuccess: (data) => {
      // Handle success here if needed
    },
    ...config,
  });
};
