import { useState, useEffect } from 'react';
import { API_URL } from '@/config';
import storage from '@/utils/storage';
import { useAppContext } from '@/context/AppContext';

export const usePodStatus = (enabled: boolean) => {
  const [allPodsSSE, setAllPods] = useState<any[]>([]);  // Updated to handle array of pods
  const [eventSource, setEventSource] = useState<EventSource | null>(null);
  const access_token = storage.getToken();
  const { xTeamId: xTeamIdFromTeamApi } = useAppContext();

  const establishSSEConnection = () => {
    const teamData = localStorage.getItem('flowscale_hub_team');
    const xTeamIdFromLocalStorage = teamData ? JSON.parse(teamData)._id : null;

    const xTeamId = xTeamIdFromTeamApi || xTeamIdFromLocalStorage;

    if (!enabled || !access_token || !xTeamId) return;

    const url = `${API_URL}/v2/pod/status/stream?access_token=${access_token}&x_team=${xTeamId}`;

    const newEventSource = new EventSource(url);
    setEventSource(newEventSource);

    newEventSource.onmessage = (event) => {
      const data = JSON.parse(event.data);
      // Append new data to allPodsSSE array
      setAllPods((prevPods) => {
        const existingPodIndex = prevPods.findIndex(pod => pod._id === data._id);
        if (existingPodIndex !== -1) {
          // Update existing pod data
          const updatedPods = [...prevPods];
          updatedPods[existingPodIndex] = data;
          return updatedPods;
        } else {
          // Add new pod data
          return [...prevPods, data];
        }
      });
    };

    newEventSource.onerror = (error) => {
      console.error('SSE error:', error);
    };

    return () => {
      newEventSource.close();
    };
  };

  useEffect(() => {
    establishSSEConnection();
  }, [enabled]);

  useEffect(() => {
    if (!enabled && eventSource) {
      eventSource.close();
      setEventSource(null);
    }
  }, [enabled]);

  const terminateSSEConnection = () => {
    if (eventSource) {
      eventSource.close();
      setEventSource(null);
    }
  };

  return { allPodsSSE, establishSSEConnection, terminateSSEConnection };
};
