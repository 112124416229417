import { useState, useEffect } from 'react';
import { API_URL } from '@/config';
import storage from '@/utils/storage';
import { useAppContext } from '@/context/AppContext';

export const useUserCredits = (enabled: boolean) => {
  const [userCredits, setUserCredits] = useState<any>({});
  const [eventSource, setEventSource] = useState<EventSource | null>(null);
  const access_token = storage.getToken();

  // Function to establish SSE connection
  const establishSSEConnection = () => {
    const teamData = localStorage.getItem('flowscale_hub_team');
    const xTeamIdFromLocalStorage = teamData ? JSON.parse(teamData)._id : null;

    const xTeamId = xTeamIdFromLocalStorage;

    if (!enabled || !xTeamId) return;

    const url = `${API_URL}/v1/credit/stream?access_token=${access_token}&x_team=${xTeamId}`;

    const newEventSource = new EventSource(url);
    setEventSource(newEventSource);

    newEventSource.onmessage = (event) => {
      const data = JSON.parse(event.data);
      // Handle received SSE data (active cluster data)
      setUserCredits(data);
    };

    newEventSource.onerror = (error) => {
      console.error('SSE error:', error);
      terminateSSEConnection();
    };

    return () => {
      newEventSource.close();
    };
  };

  useEffect(() => {
    establishSSEConnection();
  }, [enabled]);

  useEffect(() => {
    if (!enabled && eventSource) {
      eventSource.close();
      setEventSource(null);
    }
  }, [enabled]);

  // Function to close SSE connection
  const terminateSSEConnection = () => {
    if (eventSource) {
      eventSource.close();
      setEventSource(null);
    }
  };

  return { userCredits, establishSSEConnection, terminateSSEConnection };
};
