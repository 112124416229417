import { useQuery } from 'react-query';
import axios from 'axios';
import { useNotificationStore } from '@/stores/notifications';

interface GetQueueDataParams {
  api_url: string;
}

export const getQueueData = async ({ api_url }: GetQueueDataParams): Promise<any> => {
  if (!api_url) {
    return;
  }
  try {
    const response = await axios.get(`${api_url}/queue`);
    return response;
  } catch (error) {
    // console.error('Error fetching queue data:', error);
    // throw error;
  }
};

export const useGetQueueData = (api_url: string, config = {}) => {
  const { addNotification } = useNotificationStore();

  return useQuery<any, Error>({
    queryKey: ['queueData', api_url],
    queryFn: () => getQueueData({ api_url }),
    refetchInterval: 1500,
    onError: (error) => {
      addNotification({
        type: 'error',
        title: 'Failed to fetch queue data',
        message: error.message,
      });
    },
    onSuccess: (data) => {},
    ...config,
  });
};
