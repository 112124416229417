import { useQuery } from 'react-query';
import { axios } from '@/lib/axios';
import { useNotificationStore } from '@/stores/notifications';
import storage from '@/utils/storage';

export const getAllCommitsByPath = async (
  username: string,
  repo_name: string,
  path: string
): Promise<any> => {
  const access_token = storage.getToken();
  const GITHUB_API_URL = 'https://api.github.com';

  if (!access_token) {
    return;
  }
  try {
    if (username && repo_name && path) {
      const response = await axios.get(
        `${GITHUB_API_URL}/repos/${username}/${repo_name}/commits?path=${path}&page=1&per_page=100${Date.now()}`,
        {
          owner: 'OWNER',
          repo: repo_name,
          headers: {
            'X-GitHub-Api-Version': '2022-11-28',
          },
        }
      );
      return response;
    }
  } catch (error) {
    // console.error('Error getting commits:', error);
    // throw error;
  }
};

export const useGetAllCommitsByPath = (
  username: string,
  repo_name: string,
  path: string,
  config = {}
) => {
  const { addNotification } = useNotificationStore();

  return useQuery<any, Error>(
    ['allCommitsByPath', username, repo_name, path],
    () => getAllCommitsByPath(username, repo_name, path),
    {
      onError: (error) => {
        addNotification({
          type: 'error',
          title: 'Failed to fetch commits',
          message: error.message,
        });
      },
      onSuccess: (data) => {},
      ...config,
    }
  );
};
