import React from 'react';
import { ActionMenu, Box } from '@primer/react';
import { DotFillIcon } from '@primer/octicons-react';
import { CurrencyDollarSimple, Spinner } from 'phosphor-react';

type DropdownMorphedProps = {
  children: React.ReactNode;
  status: string;
  creditsLoading: boolean;
  credits: any;
};

export const DropdownMorphed: React.FC<DropdownMorphedProps> = ({
  children,
  status,
  creditsLoading,
  credits,
}) => {
  // Dynamic color based on status
  const statusColor =
    status === 'running' || status === 'cold-starting' || status === 'initializing'
      ? '#14C078'
      : status === 'idle'
      ? '#ffc107'
      : status === 'initializing' || status === 'deploying'
      ? '#bdbdbd'
      : 'grey'; // Stopped or Error state

  return (
    <ActionMenu>
      <ActionMenu.Button
        disabled={creditsLoading}
        alignContent="center"
        sx={{
          border: `1px solid #464b51`,
          borderRadius: '6px',
          fontSize: '12px',
          backgroundColor:
            'var(--bgColor-neutral-muted, var(--color-neutral-subtle, rgba(99,110,123,0.1)))',
        }}
      >
        {creditsLoading ? (
          <Spinner size={18} className="animate-spin" />
        ) : (
          <Box className="flex items-center gap-1">
            <DotFillIcon fill={statusColor} />
            <span className="flex items-center gap-1">
              Credits:
              <p className="flex items-center">
                <CurrencyDollarSimple /> {credits}
              </p>
            </span>
          </Box>
        )}
      </ActionMenu.Button>
      <ActionMenu.Overlay
        height="fit-content"
        align="end"
        sx={{
          borderRadius: '0.25rem',
          backgroundColor: '#16191D',
          width: '300px',
        }}
      >
        <Box className="flex flex-col h-full overflow-y-auto">
          <p className="text-sm border-b border-[#636C76] px-5 py-2">Active Resources</p>
          <Box className="flex flex-col gap-3.5 overflow-y-auto" sx={{ maxHeight: '330px' }}>
            {children}
          </Box>
        </Box>
      </ActionMenu.Overlay>
    </ActionMenu>
  );
};
