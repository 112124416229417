import * as React from 'react';

export const NoInternetConnectionPage = () => {
  return (
    <div className="flex items-center justify-center w-screen h-screen bg-[#16191D]">
      <div className="text-center text-white">
        <h1 className="text-4xl font-extrabold mb-4">No internet connection!</h1>
        <p className="text-lg mb-8">Please check your internet connection and try again. </p>
      </div>
    </div>
  );
};
