import React, { createContext, useContext, useRef, useState } from 'react';
import LoadingBar, { LoadingBarRef } from 'react-top-loading-bar';

interface LoadingBarContextType {
  start: () => void;
  complete: () => void;
  setProgress: (value: number) => void;
  isLoading: boolean; // Add isLoading state to context
}

const LoadingBarContext = createContext<LoadingBarContextType | undefined>(undefined);

export const useLoadingBar = () => {
  const context = useContext(LoadingBarContext);
  if (!context) {
    throw new Error('useLoadingBar must be used within a LoadingBarProvider');
  }
  return context;
};

export const LoadingBarProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const ref = useRef<LoadingBarRef>(null);
  const [isLoading, setIsLoading] = useState(false); // State to track loading

  const start = () => {
    setIsLoading(true); // Set loading state to true
    ref.current?.continuousStart();
  };

  const complete = () => {
    ref.current?.complete();
    setIsLoading(false); // Set loading state to false after completion
  };

  const setProgress = (value: number) => {
    ref.current?.staticStart(value);
  };

  return (
    <LoadingBarContext.Provider value={{ start, complete, setProgress, isLoading }}>
      <LoadingBar color="#fff" ref={ref} />
      {isLoading && (
        <div
          style={{
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100vw',
            height: '100vh',
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            zIndex: 9999,
            pointerEvents: 'none',
          }}
        />
      )}
      <div style={{ pointerEvents: isLoading ? 'none' : 'auto' }}>{children}</div>
    </LoadingBarContext.Provider>
  );
};
