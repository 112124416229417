import React, { useState } from 'react';
import { Box, IconButton, TextInput } from '@primer/react';
import { ClockCounterClockwise, X } from 'phosphor-react';
import { Modal } from '@/components/Elements';
import { useGetAllCommitsByPath } from '../../api/getAllCommitsByPath';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import CommitsTimeline from '../CommitsTimeline';

dayjs.extend(relativeTime);

interface ChangeHistoryModalProps {
  isOpen: boolean;
  isClose: () => void;
  username: string;
  repo_name: string;
  path: string;
}

export const ChangeHistoryModal: React.FC<ChangeHistoryModalProps> = ({
  isOpen,
  isClose,
  username,
  repo_name,
  path,
}) => {
  const [searchTerm, setSearchTerm] = useState('');

  const { data: getCommits, isLoading } = useGetAllCommitsByPath(username, repo_name, path);

  function CustomHeader() {
    return (
      <Box
        sx={{ borderBottom: '1px solid #636C76' }}
        className="p-4 flex items-center justify-between"
      >
        <Box className="flex items-center gap-2">
          <ClockCounterClockwise size={24} color="#FBBF24" />
          <h3 className="text-base font-semibold">Change History - {path.split('/').pop()}</h3>
        </Box>
        <IconButton
          aria-label=""
          icon={X}
          onClick={() => isClose()}
          unsafeDisableTooltip={false}
          variant="invisible"
          sx={{
            fontSize: '18px',
          }}
        />
      </Box>
    );
  }

  return (
    <Modal
      isOpen={isOpen}
      onDismiss={isClose}
      customHeader={CustomHeader}
      sx={{ width: '60%' }}
      showButtons={false}
    >
      <Box className="overflow-y-hidden max-h-[500px]">
        <p className="text-base font-normal leading-6">
          History of changes in this file
        </p>

        <TextInput
          block
          placeholder="Search changes..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          sx={{ mb: 3, display: 'sticky', top: 0, zIndex: 1, mt: 3 }}
        />
        <CommitsTimeline commits={getCommits} isLoading={isLoading} searchTerm={searchTerm} />
      </Box>
    </Modal>
  );
};
