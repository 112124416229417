import { AppProvider } from '@/providers/app';
import { AppRoutes } from '@/routes';
import { ThemeProvider, BaseStyles, theme } from '@primer/react';
import deepmerge from 'deepmerge';
import './index.css';
import { AppContextProvider } from '@/context/AppContext';
import { LoadingBarProvider } from './context/LoadingBarContext';
import { useEffect } from 'react';

const customTheme = deepmerge(theme, {
  fonts: {
    normal: 'avenir, sans-serif',
  },
});

function App() {
  useEffect(() => {
    const script = document.createElement('script');
    script.innerHTML = `
      !function () {
        var reb2b = window.reb2b = window.reb2b || [];
        if (reb2b.invoked) return;
        reb2b.invoked = true;
        reb2b.methods = ["identify", "collect"];
        reb2b.factory = function (method) {
          return function () {
            var args = Array.prototype.slice.call(arguments);
            args.unshift(method);
            reb2b.push(args);
            return reb2b;
          };
        };
        for (var i = 0; i < reb2b.methods.length; i++) {
          var key = reb2b.methods[i];
          reb2b[key] = reb2b.factory(key);
        }
        reb2b.load = function (key) {
          var script = document.createElement("script");
          script.type = "text/javascript";
          script.async = true;
          script.src = "" + key + "/LNKLDHM594OJ.js.gz";
          var first = document.getElementsByTagName("script")[0];
          first.parentNode.insertBefore(script, first);
        };
        reb2b.SNIPPET_VERSION = "1.0.1";
        reb2b.load("LNKLDHM594OJ");
      }();
    `;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);
  
  return (
    <AppProvider>
      <AppContextProvider>
        <LoadingBarProvider>
          <ThemeProvider colorMode="night" nightScheme="dark_dimmed" theme={customTheme}>
            <BaseStyles>
              <AppRoutes />
            </BaseStyles>
          </ThemeProvider>
        </LoadingBarProvider>
      </AppContextProvider>
    </AppProvider>
  );
}

export default App;
