import { useQuery } from 'react-query';
import { axios } from '@/lib/axios';
import { useNotificationStore } from '@/stores/notifications';
import { API_URL } from '@/config';
import storage from '@/utils/storage';

export const getTeam = async (): Promise<any> => {
  const access_token = storage.getToken();
  const team_id = storage.getTeam()?._id;

  console.log('[DEBUG] getTeam:', access_token, team_id);

  if (!access_token || !team_id) {
    return;
  }

  try {
    const response = await axios.get(`${API_URL}/v1/team?access_token=${access_token}`);
    return response;
  } catch (error) {
    // console.error('Error fetching team details:', error);
    // throw error;
  }
};

export const useGetTeam = (config = {}) => {
  const { addNotification } = useNotificationStore();

  return useQuery<any, Error>({
    queryKey: ['team'],
    queryFn: () => getTeam(),
    onError: (error) => {
      addNotification({
        type: 'error',
        title: 'Failed to fetch team details',
        message: error.message,
      });
    },
    onSuccess: (data) => {
      // Handle success here if needed
    },
    ...config,
  });
};
