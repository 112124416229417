const storagePrefix = 'flowscale_hub_';

const storage = {
  getTeam: () => {
    return JSON.parse(window.localStorage.getItem(`${storagePrefix}team`) as string);
  },
  setTeam: (teamId: string) => {
    window.localStorage.setItem(`${storagePrefix}team`, JSON.stringify(teamId));
  },
  clearTeam: () => {
    window.localStorage.removeItem(`${storagePrefix}team`);
  },
  getToken: () => {
    return JSON.parse(window.localStorage.getItem(`${storagePrefix}token`) as string);
  },
  setToken: (token: string) => {
    window.localStorage.setItem(`${storagePrefix}token`, JSON.stringify(token));
  },
  clearToken: () => {
    window.localStorage.removeItem(`${storagePrefix}token`);
  },
};

export default storage;
