import * as React from 'react';

export const NotFoundPage = () => {
  return (
    <div className="flex items-center justify-center w-screen h-screen bg-[#16191D]">
      <div className="text-center text-white">
        <h1 className="text-4xl font-extrabold mb-4">404 not found!</h1>
        <p className="text-lg mb-8">
          The page you are looking for does not exist. Please go back to the home.
        </p>
        <button
          onClick={() => window.location.assign(window.location.origin)}
          className="rounded-md px-8 py-4 bg-indigo-600 text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-700 focus:ring-opacity-50"
        >
          Go back to Home
        </button>
      </div>
    </div>
  );
};
