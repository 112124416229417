import { useQuery } from 'react-query';
import { axios } from '@/lib/axios';
import { useNotificationStore } from '@/stores/notifications';
import { API_URL } from '@/config';
import storage from '@/utils/storage';

export const getTeams = async (): Promise<any> => {
  const access_token = storage.getToken();

  if (!access_token) {
    return;
  }

  try {
    const response = await axios.get(`${API_URL}/v1/team/teams?access_token=${access_token}`);
    return response;
  } catch (error) {
    console.error('Error fetching teams list:', error);
    // throw error;
  }
};

export const useGetTeams = (config = {}) => {
  const { addNotification } = useNotificationStore();

  return useQuery<any, Error>({
    queryKey: ['teams'],
    queryFn: () => getTeams(),
    onError: (error) => {},
    onSuccess: (data) => {
      // Handle success here if needed
    },
    ...config,
  });
};
